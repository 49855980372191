const data = {
	name: 'US East (N. Virginia)',
	icon: 'Arabic',
	versions: [
		{
			name: 'Review later in cart',
			applications: [
				{
					id: 'windows',
					pricings: [				
						{
							id: 'startup',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/windows-server-startup-virginia',
						},
						{
							id: 'rocket',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/windows-server-rocket-virginia',
						},
						{
							id: 'professional',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/windows-server-professional-virginia',
						},
						{
							id: 'enterprise',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/windows-server-enterprise-virginia',
						},
					],
				},
				{
					id: 'sql',
					pricings: [				
						{
							id: 'startup',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/sql-server-startup-virginia',
						},
						{
							id: 'rocket',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/sql-server-rocket-virginia',
						},
						{
							id: 'professional',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/sql-server-professional-virginia',
						},
						{
							id: 'enterprise',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/sql-server-enterprise-virginia',
						},
					],
				},
			],
		},
	],
};

export default data;